



















































import { ConfigCreator } from "@/mixins/configurator";
import { ConfigFile } from "@/models/configurator/config_file";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import ConfigFileService from "@/services/config_file_service";
import { APIError, NotFoundError } from "@/services/error_service";

@Component
export default class Main extends mixins(ConfigCreator) {
  protected configFileService = new ConfigFileService();

  async created() {
    if (this.$route.query.design) {
      const fileShareId = this.$route.query.design as string;
      if (this.$route.query.edit) {
        const loader = this.$loading.show(
          { opacity: 1 },
          {
            before: this.$createElement(
              "h1",
              "Preparing your custom umbrella..."
            )
          }
        );
        const configFile = await this.fetchSharedConfigFile(fileShareId);
        await this.transformConfigFileAndSaveToStore(configFile);
        this.$router.replace("/designer");
        loader.hide();
      } else {
        const loader = this.$loading.show(
          { opacity: 1 },
          {
            before: this.$createElement("h1", "Loading custom umbrella view...")
          }
        );
        const configFile = await this.fetchSharedConfigFile(fileShareId);
        await this.transformConfigFileAndSaveToStore(configFile);
        this.$router.push("/view?design=" + fileShareId);
        loader.hide();
      }
    }
  }

  protected async fetchSharedConfigFile(shareId: string): Promise<ConfigFile> {
    let file = {} as ConfigFile;
    try {
      const res = await this.configFileService.getShareFile(shareId);
      file = res;
    } catch (err) {
      if (err instanceof NotFoundError) {
        NotFoundError.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
    return file;
  }

  protected beforeLeave(el: any) {
    const { marginLeft, marginTop, width, height } = window.getComputedStyle(
      el
    );
    el.style.left = `${el.offsetLeft - parseFloat(marginLeft)}px`;
    el.style.top = `${el.offsetTop - parseFloat(marginTop)}px`;
    el.style.width = width;
    el.style.height = height;
  }

  protected async start(): Promise<void> {
    this.$router.push("/collectionmenu");
  }
}
